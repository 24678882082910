






import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutShare: () => import("@/layouts/presets/share.vue"),
  },
})
export default class PageShare extends Vue {}
